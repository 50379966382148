import React from "react";
import ContactInformationPopulator from "../utils/ContactInformationPopulator";

export default function TopFixedInformationBar() {

    React.useEffect(() => {
        ContactInformationPopulator.populate();
    }, [])

    return (
        <div className="top-info-bar fade-in-animation">
            <div className="d-flex justify-content-evenly text-center">
                <small className="p-2">
                    <i className="bi bi-envelope-fill"/> <strong>Email:&nbsp;</strong>
                    <span className="email-address-content" title="Send us an email"/>
                </small>

                <small className="p-2">
                    <i className="bi bi-phone-vibrate-fill"/> <strong>Tel:&nbsp;</strong>
                    <span className="office-number-content" title="Give us a call"/>
                </small>
            </div>
        </div>
    )
}