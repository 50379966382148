import React from "react";
import {Link} from "react-router-dom";
import JigglySocialMediaIcons from "./JigglySocialMediaIcons";
import ContactInformation from "./ContactInformation";
import $ from "jquery";

const text = {
    aboutBriefing: 'is a registered private company that provides professional services in private tutoring online or ' +
        'at the comfort of your home and at the time that suits the needs of your child.',

    followOurSocialPlatforms: 'We\'re also available on our social platforms to assist you. Follow us to also receive important ' +
        'news and updates regarding us.',

    readyToFindTutor: 'Ready to get in touch with us about the academic future of your child? ' +
        'We\'re most definitely happy to assist your child to reach their best potential.',

    copyrightNotice: {
        firstPart: '2022. Copyright. All rights reserved. ',
        secondPart: ' Website developed by '
    }
}

export default function BottomFooterSection() {

    const initEffect = function () {
        $('.website-developer').on('click', function () {
            window.open('https://excitingsoftware.co.za', '_blank');
        })
    }

    React.useEffect(initEffect, [])

    return (
        <footer>
            <div className="container fade-in-animation">
                <div className="w-100 d-block">
                    <div className="row flex-grow-1">

                        <div className="col-lg-4">
                            <div className="empty-divider"/>

                            <div className="w-100 p-4 text-center">
                                <h1>
                                    <i className="bi bi-info-circle-fill"/>
                                </h1>
                                <h3>About us</h3>
                                <hr/>
                                <h5 className="text-danger">We're all about quality learning</h5>
                                <p>
                                    <strong>Xplain2me Tutoring (Pty) Limited</strong> {text.aboutBriefing}
                                </p>
                                <br/>
                                <Link to="/about" className="btn btn-danger" title="Learn more about us">
                                    <i className="bi bi-info-circle-fill"/> Learn more...
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="empty-divider"/>

                            <div className="w-100 p-4 text-center">
                                <h1>
                                    <i className="bi bi-phone-vibrate-fill"/>
                                </h1>
                                <h3>Let's go social, shall we?</h3>
                                <hr/>
                                <p>
                                    {text.followOurSocialPlatforms}
                                </p>

                                <JigglySocialMediaIcons/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="empty-divider"/>

                            <div className="w-100 p-4 text-center">
                                <h1>
                                    <i className="bi bi-laptop"/>
                                </h1>
                                <h3>Get a tutor today</h3>
                                <hr/>
                                <p>
                                    {text.readyToFindTutor}
                                </p>
                                <br/>

                                <ContactInformation/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="empty-divider"/>

                <div className="w-100 d-block text-muted text-center">
                    <hr/>
                    <small>
                        <Link to="/legal/terms-of-use" className="link-info" title="Terms of Use">Terms of Use</Link>
                        <span> | </span>
                        <Link to="/legal/privacy-policy" className="link-info" title="Privacy Policy">Privacy Policy</Link>
                    </small>
                </div>

                <div className="w-100 d-block text-muted text-center">
                    <hr/>
                    <small>
                        {text.copyrightNotice.firstPart}
                        <strong>Xplain2Me Tutoring (Pty) Ltd</strong>.
                        {text.copyrightNotice.secondPart}
                        <strong className="text-danger rotate-45deg-anti-clockwise website-developer">
                            Exciting Software™
                        </strong>.
                    </small>
                </div>

                <div className="empty-divider" />
            </div>
        </footer>
    )
}