import React from 'react';
import { PopupButton } from '@typeform/embed-react';

const text = {
    tooltip: 'Request a tutor instantly from us now!'
}

export default function RequestTutorPopupButton ({darkTheme = false, largeButton = true,
                                                     buttonText = "Request a tutor", enableSandbox = false }) {

    let themeCssClass = "btn";

    themeCssClass += (largeButton) ? " btn-lg" : "";
    themeCssClass += (darkTheme) ? " btn-dark" : " btn-light";

    return (
        <PopupButton id="vV2IzhGd" className={themeCssClass} title={text.tooltip}
                     ondblclick={false} enableSandbox={enableSandbox} tooltip={text.tooltip}>
            <i className="bi bi-arrow-up-right-circle-fill"/> {buttonText}
        </PopupButton>
    )
}