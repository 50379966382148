import React from "react";
import QuotePageHeader from "../../components/QuotePageHeader";

const privacyPolicyDocument = {
    dateLastUpdated: '01 August 2022',
    revisionNumber: 'Version 1.1',

    interpretation: 'The words of which the initial letter is capitalized have meanings defined under the ' +
        'following conditions. The following definitions shall have the same meaning regardless of ' +
        'whether they appear in singular or in plural.',

    contactInformation: {
        emailAddress: 'info@xplain2me.co.za',
        postalAddress: '15 Soetdoring Avenue, Weltevredenpark, Roodepoort, 1709'
    },

    definitions: [
        {
            phrase: 'Account',
            reference: 'refers to a unique account created for You to access our Service or parts of our Service.'
        },
        {
            phrase: 'Company',
            reference: '(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Xplain2Me ' +
                'Tutoring (Pty) Limited, 15 Soetdoring Avenue, Weltevredenpark, Roodepoort, 1709.'
        },
        {
            phrase: 'Cookies',
            reference: 'refers to small files that are placed on Your computer, mobile device or any other device by ' +
                'a website, containing the details of Your browsing history on that website among its many uses.'
        },
        {
            phrase: 'Country',
            reference: 'refers to the Republic of South Africa.'
        },
        {
            phrase: 'Device',
            reference: 'refers to any device that can access the Service such as a computer, a cellphone or a digital tablet.'
        },
        {
            phrase: 'Personal Data',
            reference: 'refers to any information that relates to an identified or identifiable individual.'
        },
        {
            phrase: 'Service',
            reference: 'refers to the Website'
        },
        {
            phrase: 'Service Provider',
            reference: 'means any natural or legal person who processes the data on behalf of the Company. ' +
                'It refers to third-party companies or individuals employed by the Company to facilitate the Service, ' +
                'to provide the Service on behalf of the Company, to perform services related to the Service or to ' +
                'assist the Company in analyzing how the Service is used.'
        },
        {
            phrase: 'Third-party Social Media Service',
            reference: 'refers to any website or any social network website through which a User can log in or ' +
                'create an account to use the Service.'
        },
        {
            phrase: 'Usage Data',
            reference: 'refers to data collected automatically, either generated by the use of the Service or from the ' +
                'Service infrastructure itself (for example, the duration of a page visit).'
        },
        {
            phrase: 'Website',
            reference: 'refers to Xplain2Me Tutoring, accessible from https://www.xplain2me.co.za'
        },
        {
            phrase: 'You',
            reference: 'means the individual accessing or using the Service, or the company, or other legal entity on ' +
                'behalf of which such individual is accessing or using the Service, as applicable.'
        }
    ],

    sections: [
        {
            title: 'Collecting and Using Your Personal Data',
            introduction: 'Under this section, we define the type of personal data that we collect:',
            terms: [
                {
                    title: 'Personal Data',
                    descriptions: [
                        'While using Our Service, We may ask You to provide Us with certain personally identifiable ' +
                        'information that can be used to contact or identify You. Personally identifiable information may ' +
                        'include, but is not limited to: (i) First and Last Names, (ii) Email Address, (iii) Phone Number ' +
                        'and (iv) Usage Data.'
                    ]
                },
                {
                    title: 'Usage Data',
                    descriptions: [
                        'Usage Data is collected automatically when using the Service.',

                        'Usage Data may include information such as Your Device\'s Internet Protocol address (e.g. IP address), ' +
                        'browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,' +
                        ' the time spent on those pages, unique device identifiers and other diagnostic data.',

                        'When You access the Service by or through a mobile device, We may collect certain information ' +
                        'automatically, including, but not limited to, the type of mobile device You use, Your mobile ' +
                        'device unique ID, the IP address of Your mobile device, Your mobile operating system, the type ' +
                        'of mobile Internet browser You use, unique device identifiers and other diagnostic data.',

                        'We may also collect information that Your browser sends whenever You visit our Service or ' +
                        'when You access the Service by or through a mobile device.'
                    ]
                },
                {
                    title: 'Information from Third-Party Social Media Services',
                    descriptions: [
                        'The Company allows You to create an account and log in to use the Service through the following ' +
                        'Third-party Social Media Services: (i) Google, (ii) Facebook and (iii) Twitter',

                        'If You decide to register through or otherwise grant us access to a Third-Party Social Media ' +
                        'Service, We may collect Personal data that is already associated with Your Third-Party Social ' +
                        'Media Service\'s account, such as Your name, Your email address, Your activities or Your contact ' +
                        'list associated with that account.',

                        'You may also have the option of sharing additional information with the Company through Your ' +
                        'Third-Party Social Media Service\'s account. If You choose to provide such information and ' +
                        'Personal Data, during registration or otherwise, You are giving the Company permission to use, ' +
                        'share, and store it in a manner consistent with this Privacy Policy.'
                    ]
                },
                {
                    title: 'Tracking Technologies and Cookies',
                    descriptions: [
                        'We use Cookies and similar tracking technologies to track the activity on Our Service ' +
                        'and store certain information. Tracking technologies used are beacons, tags, and scripts to ' +
                        'collect and track information and to improve and analyze Our Service. The technologies ' +
                        'We use may include:',

                        '(i) Cookies or Browser Cookies: A cookie is a small file placed on Your Device. You can instruct ' +
                        'Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, ' +
                        'if You do not accept Cookies, You may not be able to use some parts of our Service. Unless ' +
                        'you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.',

                        '(ii) Flash Cookies: Certain features of our Service may use local stored objects (or Flash ' +
                        'Cookies) to collect and store information about Your preferences or Your activity on our Service. ' +
                        'Flash Cookies are not managed by the same browser settings as those used for Browser Cookies.',

                        '(iii) Web Beacons. Certain sections of our Service and our emails may contain small electronic ' +
                        'files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) ' +
                        'that permit the Company, for example, to count users who have visited those pages or opened an ' +
                        'email and for other related website statistics (for example, recording the popularity of a ' +
                        'certain section and verifying system and server integrity).',

                        'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal ' +
                        'computer or mobile device when You go offline, while Session Cookies are deleted as soon as ' +
                        'You close Your web browser.',

                        'We use both Session and Persistent Cookies for the purposes set out below:',

                        'Session Cookies are essential to provide You with services available through the Website and ' +
                        'to enable You to use some of its features. They help to authenticate users and prevent ' +
                        'fraudulent use of user accounts. Without these Cookies, the services that You have asked for ' +
                        'cannot be provided, and We only use these Cookies to provide You with those services',

                        'Functionality Cookies allow us to remember choices You make when You use the Website, such ' +
                        'as remembering your login details or language preference. The purpose of these Cookies ' +
                        'is to provide You with a more personal experience and to avoid You having to re-enter ' +
                        'your preferences every time You use the Website.'
                    ]
                }
            ]
        },
        {
            title: 'Use of Your Personal Data',
            introduction: 'The Company may use Personal Data for the following purposes:',
            terms: [
                {
                    title: 'Provisions of Services',
                    descriptions: [
                        'To provide and maintain our Service, including to monitor the usage of our Service.'
                    ]
                },
                {
                    title: 'Management of your account',
                    descriptions: [
                        'To manage Your registration as a user of the Service. The Personal Data You provide can give ' +
                        'You access to different functionalities of the Service that are available to You as a registered user'
                    ]
                },
                {
                    title: 'Monitor performance of a contract',
                    descriptions: [
                        'the development, compliance and undertaking of the purchase contract for the products, ' +
                        'items or services You have purchased or of any other contract with Us through the Service'
                    ]
                },
                {
                    title: 'Contacting You',
                    descriptions: [
                        'To contact You by email, telephone calls, SMS, or other equivalent forms of electronic ' +
                        'communication, such as a mobile application\'s push notifications regarding updates or ' +
                        'informative communications related to the functionalities, products or contracted services, ' +
                        'including the security updates, when necessary or reasonable for their implementation',

                        'To provide You with news, special offers and general information about other goods, ' +
                        'services and events which we offer that are similar to those that you have already ' +
                        'purchased or enquired about unless You have opted not to receive such information.'
                    ]
                },
                {
                    title: 'For Other Purposes',
                    descriptions: [
                        'We may use Your information for other purposes, such as data analysis, identifying usage ' +
                        'trends, determining the effectiveness of our promotional campaigns and to evaluate and ' +
                        'improve our Service, products, services, marketing and your experience.'
                    ]
                }
            ]
        },
        {
            title: 'Retention of Your Personal Data',
            introduction: 'We define the circumstances under which we may retain your personal data:',
            terms: [
                {
                    title: 'Legal obligations',
                    descriptions: [
                        'The Company will retain Your Personal Data only for as long as is necessary for the purposes ' +
                        'set out in this Privacy Policy. We will retain and use Your Personal Data to the extent ' +
                        'necessary to comply with our legal obligations (for example, if we are required to retain ' +
                        'your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.'
                    ]
                },
                {
                    title: 'Internal Analysis',
                    descriptions: [
                        'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally ' +
                        'retained for a shorter period of time, except when this data is used to strengthen the security ' +
                        'or to improve the functionality of Our Service, or We are legally obligated to retain this data ' +
                        'for longer time periods.'
                    ]
                }
            ]
        },
        {
            title: 'Transfer of Your Personal Data',
            introduction: 'We define whether we transfer your personal data and if so, what other parties may ' +
                'have access to your personal data:',
            terms: [
                {
                    title: 'Internal & External Processing of your Personal Data',
                    descriptions: [
                        'Your information, including Personal Data, is processed at the Company\'s operating offices ' +
                        'and in any other places where the parties involved in the processing are located. It means ' +
                        'that this information may be transferred to — and maintained on — computers located outside ' +
                        'of Your state, province, country or other governmental jurisdiction where the data protection ' +
                        'laws may differ than those from Your jurisdiction.',

                        'our consent to this Privacy Policy followed by Your submission of such information represents ' +
                        'Your agreement to that transfer.',

                        'The Company will take all steps reasonably necessary to ensure that Your data is treated securely ' +
                        'and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place ' +
                        'to an organization or a country unless there are adequate controls in place including the ' +
                        'security of Your data and other personal information.'
                    ]
                }
            ]
        },
        {
            title: 'Disclosure of Your Personal Data',
            introduction: 'We define whether your personal data is disclosed to third parties:',
            terms: [
                {
                    title: 'Business Transactions',
                    descriptions: [
                        'If the Company is involved in a merger, acquisition or asset sale, Your Personal Data ' +
                        'may be transferred. We will provide notice before Your Personal Data is transferred and ' +
                        'becomes subject to a different Privacy Policy.'
                    ]
                },
                {
                    title: 'Law enforcement',
                    descriptions: [
                        'Under certain circumstances, the Company may be required to disclose Your Personal Data ' +
                        'if required to do so by law or in response to valid requests by public authorities ' +
                        '(e.g. a court or a government agency).'
                    ]
                },
                {
                    title: 'Other legal requirements',
                    descriptions: [
                        'The Company may disclose Your Personal Data in the good faith belief that such action is necessary to: ',

                        'Comply with a legal obligation, ' +
                        'protect and defend the rights or property of the Company, ' +
                        'prevent or investigate possible wrongdoing in connection with the Service, ' +
                        'protect the personal safety of Users of the Service or the public and ' +
                        'protect against legal liability'
                    ]
                }
            ]
        },
        {
            title: 'Security of Your Personal Data',
            introduction: 'We define our measures and efforts to protect and secure your personal data:',
            terms: [
                {
                    title: 'Measures of Securing your Personal Data',
                    descriptions: [
                        'The security of Your Personal Data is important to Us, however no method of transmission ' +
                        'over the Internet, or method of electronic storage is 100% secure. While We strive to use ' +
                        'commercially acceptable means to protect Your Personal Data, We cannot guarantee its ' +
                        'absolute security.'
                    ]
                }
            ]
        },
        {
            title: 'Children\'s Privacy',
            introduction: 'We define whether we collect, process and transfer personal data of person\'s under ' +
                'the age of 13:',
            terms: [
                {
                    title: 'No Provisions for Children Under 13',
                    descriptions: [
                        'Our Service does not address anyone under the age of 13. We do not knowingly collect ' +
                        'personally identifiable information from anyone under the age of 13. If You are a parent or ' +
                        'guardian and You are aware that Your child has provided Us with Personal Data, please ' +
                        'contact Us. If We become aware that We have collected Personal Data from anyone under the ' +
                        'age of 13 without verification of parental consent, We take steps to remove that information ' +
                        'from Our servers.',

                        'If We need to rely on consent as a legal basis for processing Your information and Your ' +
                        'country requires consent from a parent, We may require Your parent\'s consent ' +
                        'before We collect and use that information.'
                    ]
                }
            ]
        },
        {
            title: 'Links to Other Websites',
            introduction: 'We define our liability of links to other websites:',
            terms: [
                {
                    title: 'No Liability',
                    descriptions: [
                        'Our Service may contain links to other websites that are not operated by Us. ' +
                        'If You click on a third party link, You will be directed to that third party\'s site. ' +
                        'We strongly advise You to review the Privacy Policy of every site You visit.',

                        'We have no control over and assume no responsibility for the content, privacy policies or ' +
                        'practices of any third party sites or services.'
                    ]
                }
            ]
        },
        {
            title: 'Changes to this Policy',
            introduction: 'We define our right to change this policy and how you will be notified of these changes:',
            terms: [
                {
                    title: 'Right Reserved',
                    descriptions: [
                        'We may update Our Privacy Policy from time to time. We will notify You of any changes' +
                        ' by posting the new Privacy Policy on this page.',

                        'We will let You know via email and/or a prominent notice on Our Service, prior to the change ' +
                        'becoming effective and update the "Last updated" date at the top of this Privacy Policy.',

                        'You are advised to review this Privacy Policy periodically for any changes. Changes to this ' +
                        'Privacy Policy are effective when they are posted on this page.'
                    ]
                }
            ]
        }
    ]
}

const quote = {
    text: "Education is not the learning of facts, but the training of the mind.",
    source: "Albert Einstein"
}

export default function PrivacyPolicy() {

    const definitions = privacyPolicyDocument.definitions;
    const sections = privacyPolicyDocument.sections;

    return (
        <React.Fragment>
            <header>
                <QuotePageHeader headerTitle="Privacy Policy"
                                 quote={quote}/>
            </header>

            <main>
                <div className="w-100 d-block no-padding">
                    <div className="container">
                        <div className="empty-divider"/>

                        <h2>Privacy Policy</h2>

                        <p className="text-muted">Last Updated: {privacyPolicyDocument.dateLastUpdated}</p>
                        <p className="text-muted">Revision: {privacyPolicyDocument.revisionNumber}</p>
                        <br/>

                        <h4>Interpretations & Definitions</h4>
                        <p>{privacyPolicyDocument.interpretation}</p>
                        <br/>

                        <h4>Definitions</h4>
                        <ul>
                            {definitions.map((definition, key) => {
                                return (
                                    <li key={key}>
                                        <strong>{definition.phrase}</strong> {definition.reference}
                                    </li>
                                )
                            })}
                        </ul>

                        <br/>

                        {sections.map((section, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <h3>{section.title}</h3>
                                    <p>{section.introduction}</p>
                                    <br/>

                                    {section.terms.map((term, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <h5>{term.title}</h5>

                                                {term.descriptions.map((description, key) => {
                                                    return (
                                                        <p key={key}>{description}</p>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}

                        <br/>

                        <h4>Contact Information</h4>
                        <p>
                            <strong>Email: </strong> {privacyPolicyDocument.contactInformation.emailAddress}<br/>
                            <strong>Address: </strong> {privacyPolicyDocument.contactInformation.postalAddress}
                        </p>

                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}