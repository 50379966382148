import React from "react";
import TopFixedInformationBar from "./components/TopFixedInformationBar";
import TopFixedNavigationBar from "./components/TopFixedNavigationBar";
import BottomFooterSection from "./components/BottomFooterSection";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import ResourceNotFound from "./pages/error/ResourceNotFound";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import TermsAndConditions from "./pages/legal/TermsAndConditions";
import SessionConfigOptions from "./utils/SessionConfigOptions";
import SessionStoreManager from "./utils/SessionStoreManager";

const setupSessionConfigOptions = function () {
    const sessionConfigOptions = new SessionConfigOptions();
    sessionConfigOptions.sentCallBackRequest = false;

    SessionStoreManager.setAttribute(SessionConfigOptions.ATTRIBUTE_NAME, sessionConfigOptions);
}

export default function App() {

    const init = function () {
        setupSessionConfigOptions();
    }

    React.useEffect(init, []);

    return (
        <React.Fragment>
            <TopFixedInformationBar/>

            <TopFixedNavigationBar/>

            <Routes>
                <Route exact path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/services" element={<Services/>}/>
                <Route path="/find-a-tutor" element={<Contact/>}/>
                <Route path="/legal/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/legal/terms-of-use" element={<TermsAndConditions/>}/>
                <Route path="*" element={<ResourceNotFound/>}/>
            </Routes>

            <BottomFooterSection/>
        </React.Fragment>
    );
}