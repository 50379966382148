import React from "react";
import QuotePageHeader from "../../components/QuotePageHeader";

const termsOfUseDocument = {
    dateLastUpdated: '01 August 2022',
    revisionNumber: 'Version 1.1',

    introduction: [
        'The Xplain2Me Tutoring website located at www.xplain2me.co.za is a copyrighted work belonging to ' +
        'Xplain2Me Tutoring (Pty) Ltd. Certain features of the Site may be subject to additional guidelines, terms, ' +
        'or rules, which will be posted on the Site in connection with such features.',

        'All such additional terms, guidelines, and rules are incorporated by reference into these Terms.',

        'These Terms of Use described the legally binding terms and conditions that oversee your use of the Site. ' +
        'BY LOGGING INTO THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and you represent that you have the ' +
        'authority and capacity to enter into these Terms. IF YOU DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, ' +
        'DO NOT LOG INTO AND/OR USE THE SITE.'
    ],

    sections: [
        {
            title: '1. Access to the Site',
            terms: [
                {
                    title: '1.1 Subject to these Terms',
                    description: 'Company grants you a non-transferable, non-exclusive, revocable, limited license ' +
                        'to access the Site solely for your own personal, non-commercial use.'
                },
                {
                    title: '1.2 Certain restrictions',
                    description: 'The rights approved to you in these Terms are subject to the following restrictions:' +
                        ' (a) you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise ' +
                        'commercially exploit the Site; (b) you shall not change, make derivative works of, disassemble, ' +
                        'reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site ' +
                        'in order to build a similar or competitive website; and (d) except as expressly stated herein, ' +
                        'no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, ' +
                        'posted or transmitted in any form or by any means unless otherwise indicated, any future ' +
                        'release, update, or other addition to functionality of the Site shall be subject to these ' +
                        'Terms. All copyright and other proprietary notices on the Site must be retained on all ' +
                        'copies thereof.'
                },
                {
                    title: '1.3 Right to change or suspend',
                    description: 'Company reserves the right to change, suspend, or cease the Site with or without ' +
                        'notice to you. You approved that Company will not be held liable to you or any third-party ' +
                        'for any change, interruption, or termination of the Site or any part.'
                },
                {
                    title: '1.4 Support to the Site',
                    description: 'You agree that Company will have no obligation to provide you with any support ' +
                        'in connection with the Site.'
                },
                {
                    title: '1.5 Intellectual Property Rights',
                    description: 'Excluding any User Content that you may provide, you are aware that all the ' +
                        'intellectual property rights, including copyrights, patents, trademarks, and trade secrets, ' +
                        'in the Site and its content are owned by Company or Company’s suppliers. Note that these ' +
                        'Terms and access to the Site do not give you any rights, title or interest in or to any ' +
                        'intellectual property rights, except for the limited access rights expressed in Section 2.1.' +
                        ' Company and its suppliers reserve all rights not granted in these Terms.'
                }
            ]
        },
        {
            title: '2. Third-Party Links',
            terms: [
                {
                    title: '2.1 Third-Party Links and Advertisements',
                    description: 'The Site may contain links to third-party websites and services, and/or display ' +
                        'advertisements for third-parties. Such Third-Party Links & Advertisements are not under the ' +
                        'control of Company, and Company is not responsible for any Third-Party Links & Advertisements. ' +
                        'Company provides access to these Third-Party Links & Advertisements only as a convenience to ' +
                        'you, and does not review, approve, monitor, endorse, warrant, or make any representations with ' +
                        'respect to Third-Party Links & Advertisements. You use all Third-Party Links & Advertisements ' +
                        'at your own risk, and should apply a suitable level of caution and discretion in doing so.' +
                        ' When you click on any of the Third-Party Links & Advertisements, the applicable third party’s ' +
                        'terms and policies apply, including the third party’s privacy and data gathering practices.'
                },
                {
                    title: '2.2 Other Users',
                    description: 'Each Site user is solely responsible for any and all of its own User Content. ' +
                        'Because we do not control User Content, you acknowledge and agree that we are not responsible ' +
                        'for any User Content, whether provided by you or by others. You agree that Company will not ' +
                        'be responsible for any loss or damage incurred as the result of any such interactions. ' +
                        'If there is a dispute between you and any Site user, we are under no obligation to become involved.'
                },
                {
                    title: '2.3 Waiver',
                    description: 'You hereby release and forever discharge the Company and our officers, employees, ' +
                        'agents, successors, and assigns from, and hereby waive and relinquish, each and every past, ' +
                        'present and future dispute, claim, controversy, demand, right, obligation, liability, action ' +
                        'and cause of action of every kind and nature, that has arisen or arises directly or indirectly ' +
                        'out of, or that relates directly or indirectly to, the Site.'
                },
                {
                    title: '2.4 Cookies',
                    description: 'Like any other website, Xplain2Me Tutoring uses ‘cookies’. These cookies are used to ' +
                        'store information including visitors’ preferences, and the pages on the website that the ' +
                        'visitor accessed or visited. The information is used to optimize the users’ experience by ' +
                        'customizing our web page content based on visitors’ browser type and/or other information.'
                }
            ]
        },
        {
            title: '3. Disclaimers',
            terms: [
                {
                    title: '3.1 Warranty',
                    description: 'The site is provided on an "as-is" and "as available" basis, and company and our ' +
                        'suppliers expressly disclaim any and all warranties and conditions of any kind, whether express, ' +
                        'implied, or statutory, including all warranties or conditions of merchantability, fitness for ' +
                        'a particular purpose, title, quiet enjoyment, accuracy, or non-infringement. We and our ' +
                        'suppliers make not guarantee that the site will meet your requirements, will be available on ' +
                        'an uninterrupted, timely, secure, or error-free basis, or will be accurate, reliable, free of ' +
                        'viruses or other harmful code, complete, legal, or safe. If applicable law requires any ' +
                        'warranties with respect to the site, all such warranties are limited in duration to ninety ' +
                        '(90) days from the date of first use.'
                },
                {
                    title: '3.2 Exclusions',
                    description: 'Some jurisdictions do not allow the exclusion of implied warranties, so the above ' +
                        'exclusion may not apply to you. Some jurisdictions do not allow limitations on how long an ' +
                        'implied warranty lasts, so the above limitation may not apply to you.'
                }
            ]
        },
        {
            title: '4. Limitation on Liability',
            terms: [
                {
                    title: '4.1 Scope of Liability',
                    description: 'To the maximum extent permitted by law, in no event shall company or our suppliers be ' +
                        'liable to you or any third-party for any lost profits, lost data, costs of procurement of ' +
                        'substitute products, or any indirect, consequential, exemplary, incidental, special or ' +
                        'punitive damages arising from or relating to these terms or your use of, or incapability to ' +
                        'use the site even if company has been advised of the possibility of such damages. Access ' +
                        'to and use of the site is at your own discretion and risk, and you will be solely responsible ' +
                        'for any damage to your device or computer system, or loss of data resulting therefrom.'
                },
                {
                    title: '4.2 Exclusions',
                    description: 'Some jurisdictions do not allow the limitation or exclusion of liability for ' +
                        'incidental or consequential damages, so the above limitation or exclusion may not apply to you.'
                },
                {
                    title: '4.3 Termination',
                    description: 'Subject to this Section, these Terms will remain in full force and effect while you ' +
                        'use the Site. We may suspend or terminate your rights to use the Site at any time for any ' +
                        'reason at our sole discretion, including for any use of the Site in violation of these Terms. ' +
                        'Upon termination of your rights under these Terms, your Account and right to access and use ' +
                        'the Site will terminate immediately. You understand that any termination of your Account may ' +
                        'involve deletion of your User Content associated with your Account from our live databases. ' +
                        'Company will not have any liability whatsoever to you for any termination of your rights under ' +
                        'these Terms. Even after your rights under these Terms are terminated, all other remaining ' +
                        'Terms not affected will remain in effect.'
                }
            ]
        },
        {
            title: '5. Copyright Policy',
            terms: [
                {
                    title: '5.1 Intellectual Property of Others',
                    description: 'Company respects the intellectual property of others and asks that users of our Site ' +
                        'do the same. In connection with our Site, we have adopted and implemented a policy respecting ' +
                        'copyright law that provides for the removal of any infringing materials and for the termination ' +
                        'of users of our online Site who are repeated infringes of intellectual property rights, ' +
                        'including copyrights. If you believe that one of our users is, through the use of our Site, ' +
                        'unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing ' +
                        'material removed, the following information in the form of a written notification must be ' +
                        'provided to our designated Copyrights officer:'
                },
                {
                    title: '',
                    description: '(i) your physical or electronic signature;\n' +
                        '(ii) identification of the copyrighted work(s) that you claim to have been infringed;\n' +
                        '(iii) identification of the material on our services that you claim is infringing and that' +
                        ' you request us to remove;\n' +
                        '(iv) sufficient information to permit us to locate such material;\n' +
                        '(v) your address, telephone number, and e-mail address;\n' +
                        '(vi) a statement that you have a good faith belief that use of the objectionable material ' +
                        'is not authorized by the copyright owner, its agent, or under the law; and\n' +
                        '(vii) a statement that the information in the notification is accurate, and under penalty of perjury, ' +
                        'that you are either the owner of the copyright that has allegedly been infringed or that you are ' +
                        'authorized to act on behalf of the copyright owner.'
                }
            ]
        },
        {
            title: '6. General',
            terms: [
                {
                    title: '6.1 Right to Modify Terms',
                    description: 'These Terms are subject to occasional revision, and if we make any substantial changes, ' +
                        'we may notify you by sending you an e-mail to the last e-mail address you provided to us and/or ' +
                        'by prominently posting notice of the changes on our Site. You are responsible for providing us ' +
                        'with your most current e-mail address. In the event that the last e-mail address that you have ' +
                        'provided us is not valid our dispatch of the e-mail containing such notice will nonetheless ' +
                        'constitute effective notice of the changes described in the notice. Any changes to these Terms ' +
                        'will be effective upon the earliest of thirty (30) calendar days following our dispatch of an ' +
                        'e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes ' +
                        'on our Site. These changes will be effective immediately for new users of our Site. Continued use ' +
                        'of our Site following notice of such changes shall indicate your acknowledgement of such changes ' +
                        'and agreement to be bound by the terms and conditions of such changes.'
                },
                {
                    title: '6.2 Notice Requirement and Informal Dispute Resolution',
                    description: 'Before either party may seek arbitration, the party must first send to the other party ' +
                        'a written Notice of Dispute describing the nature and basis of the claim or dispute, and the ' +
                        'requested relief. A Notice to the Company should be sent to: 15 Soetdoring Avenue, Weltevredenpark, ' +
                        'Roodepoort, 1709. After the Notice is received, you and the Company may attempt to resolve the claim ' +
                        'or dispute informally. If you and the Company do not resolve the claim or dispute within thirty ' +
                        '(30) days after the Notice is received, either party may begin an arbitration proceeding. ' +
                        'The amount of any settlement offer made by any party may not be disclosed to the arbitrator ' +
                        'until after the arbitrator has determined the amount of the award to which either party is entitled.'
                },
                {
                    title: '6.3 Arbitration Rules',
                    description: 'Arbitration shall be initiated following the regulations of the Republic of South Africa, ' +
                        'or alternatively using an established alternative dispute resolution provider that offers ' +
                        'arbitration as set forth in this section. If no resolution provider is not available to arbitrate, ' +
                        'the parties shall agree to select an alternative arbitration provider. The rules of the arbitration ' +
                        'provider shall govern all aspects of the arbitration except to the extent such rules are in ' +
                        'conflict with the Terms.'
                },
                {
                    title: '6.4 Additional Rules for Non-Appearance Based Arbitration',
                    description: 'If non-appearance based arbitration is elected, the arbitration shall be conducted by ' +
                        'telephone, online and/or based solely on written submissions; the specific manner shall be chosen ' +
                        'by the party initiating the arbitration. The arbitration shall not involve any personal ' +
                        'appearance by the parties or witnesses unless otherwise agreed by the parties.'
                },
                {
                    title: '6.5 Time Limits',
                    description: 'If you or the Company pursues arbitration, the arbitration action must be initiated ' +
                        'and/or demanded within the statute of limitations and within any deadline imposed under the ' +
                        'governing arbitration laws and rules for the pertinent claim.'
                },
                {
                    title: '6.6 Authority of Arbitrator',
                    description: 'If arbitration is initiated, the arbitrator will decide the rights and liabilities of ' +
                        'you and the Company, and the dispute will not be consolidated with any other matters or joined ' +
                        'with any other cases or parties. The arbitrator shall have the authority to grant motions ' +
                        'dispositive of all or part of any claim. The arbitrator shall have the authority to award ' +
                        'monetary damages, and to grant any non-monetary remedy or relief available to an individual ' +
                        'under applicable law, the AAA Rules, and the Terms. The arbitrator shall issue a written award ' +
                        'and statement of decision describing the essential findings and conclusions on which the award ' +
                        'is based. The arbitrator has the same authority to award relief on an individual basis that a ' +
                        'judge in a court of law would have. The award of the arbitrator is final and binding upon you ' +
                        'and the Company.'
                },
                {
                    title: '6.7 Confidentiality',
                    description: 'All aspects of the arbitration proceeding shall be strictly confidential. The parties ' +
                        'agree to maintain confidentiality unless otherwise required by law. This paragraph shall not ' +
                        'prevent a party from submitting to a court of law any information necessary to enforce this ' +
                        'Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.'
                },
                {
                    title: '6.8 Severability',
                    description: 'If any part or parts of this Arbitration Agreement are found under the law to be ' +
                        'invalid or unenforceable by a court of competent jurisdiction, then such specific part or ' +
                        'parts shall be of no force and effect and shall be severed and the remainder of the Agreement ' +
                        'shall continue in full force and effect.'
                },
                {
                    title: '6.9 Right to Waive',
                    description: 'Any or all of the rights and limitations set forth in this Arbitration Agreement ' +
                        'may be waived by the party against whom the claim is asserted. Such waiver shall not waive ' +
                        'or affect any other portion of this Arbitration Agreement.'
                },
                {
                    title: '6.10 Survival of Agreement',
                    description: 'This Arbitration Agreement will survive the termination of your relationship with Company.'
                },
                {
                    title: '6.11 Small Claims Court',
                    description: 'Nonetheless the foregoing, either you or the Company may bring an individual action in ' +
                        'small claims court.'
                },
                {
                    title: '6.12 Emergency Equitable Relief',
                    description: 'Anyhow the foregoing, either party may seek emergency equitable relief before a state ' +
                        'or federal court in order to maintain the status quo pending arbitration. A request for interim ' +
                        'measures shall not be deemed a waiver of any other rights or obligations under this Arbitration ' +
                        'Agreement.'
                },
                {
                    title: '6.13 Claims Not Subject to Arbitration',
                    description: 'Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud ' +
                        'and Abuse Act, and infringement or misappropriation of the other party’s patent, copyright, ' +
                        'trademark or trade secrets shall not be subject to this Arbitration Agreement.'
                },
                {
                    title: '6.14 Electronic Communications',
                    description: 'he communications between you and Company use electronic means, whether you use the ' +
                        'Site or send us emails, or whether Company posts notices on the Site or communicates with you ' +
                        'via email. For contractual purposes, you (a) consent to receive communications from Company ' +
                        'in an electronic form; and (b) agree that all terms and conditions, agreements, notices, ' +
                        'disclosures, and other communications that Company provides to you electronically satisfy ' +
                        'any legal obligation that such communications would satisfy if it were be in a hard copy writing.'
                },
                {
                    title: '6.14 Entire Terms',
                    description: 'These Terms constitute the entire agreement between you and us regarding the use of ' +
                        'the Site. Our failure to exercise or enforce any right or provision of these Terms shall not ' +
                        'operate as a waiver of such right or provision. The section titles in these Terms are for ' +
                        'convenience only and have no legal or contractual effect. The word "including" means "including ' +
                        'without limitation". If any provision of these Terms is held to be invalid or unenforceable, ' +
                        'the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision ' +
                        'will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. ' +
                        'Your relationship to Company is that of an independent contractor, and neither party is an agent ' +
                        'or partner of the other. These Terms, and your rights and obligations herein, may not be assigned, ' +
                        'subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, ' +
                        'and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing ' +
                        'will be null and void. Company may freely assign these Terms. The terms and conditions set forth ' +
                        'in these Terms shall be binding upon assignees.'
                },
                {
                    title: '6.15 Copyright/Trademark',
                    description: 'Copyright ©. All rights reserved. All trademarks, logos and service marks displayed on ' +
                        'the Site are our property or the property of other third-parties. You are not permitted to use ' +
                        'these trademarks without our prior written consent or the consent of such third party which may ' +
                        'own the trademarks.'
                }
            ]
        }
    ],

    contactInformation: {
        emailAddress: 'info@xplain2me.co.za',
        postalAddress: '15 Soetdoring Avenue, Weltevredenpark, Roodepoort, 1709'
    }
}

const quote = {
    text: "Education is not the learning of facts, but the training of the mind.",
    source: "Albert Einstein"
}

export default function TermsAndConditions() {

    const sections = termsOfUseDocument.sections;

    return (
        <React.Fragment>
            <header>
                <QuotePageHeader headerTitle="Terms of Use"
                                 quote={quote}/>
            </header>
            <main>
                <div className="w-100 d-block no-padding">
                    <div className="container">
                        <div className="empty-divider"/>

                        <h2>Terms of Use</h2>

                        <p className="text-muted">Last Updated: {termsOfUseDocument.dateLastUpdated}</p>
                        <p className="text-muted">Revision: {termsOfUseDocument.revisionNumber}</p>

                        {termsOfUseDocument.introduction.map((item, key) => {
                            return (
                                <p key={key}>{item}</p>
                            )
                        })}

                        <br/>

                        {sections.map((section, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <h4>{section.title}</h4>
                                    <br/>

                                    {section.terms.map((term, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <h5>{term.title}</h5>
                                                <p>{term.description}</p>
                                                <br/>
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}

                        <h4>Contact Information</h4>
                        <p>
                            <strong>Email: </strong> {termsOfUseDocument.contactInformation.emailAddress}<br/>
                            <strong>Address: </strong> {termsOfUseDocument.contactInformation.postalAddress}
                        </p>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}