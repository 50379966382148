import React from "react";
import $ from "jquery";
import MessageType from "../utils/MessageType";

const fadeOutAfterTimeout = function (alertContainer) {
    if (alertContainer) {
        setTimeout(function () {
            $(alertContainer).fadeOut('slow');

        }, 5000);
    }
}

const toggleFadeAnimation = function (display) {
    const alertContainer = $('.alert-container');
    if (display) {
        $(alertContainer).fadeIn('fast');
    }

    fadeOutAfterTimeout(alertContainer);
}

export default function MessageAlert({display, messageType, message}) {

    return (
        <div className="alert-container" style={{display: (display ? 'block' : 'none')}}>
            {display && messageType === MessageType.SUCCESS_MESSAGE_TYPE &&
            (
                <div className="alert alert-dark d-flex align-items-center" role="alert"
                     onLoad={toggleFadeAnimation(true)}>
                    <i className="bi bi-check-circle-fill" role="img" aria-label="Success:"/>
                    <div className="ms-2">
                        {message}
                    </div>
                </div>
            )
            }

            {display && messageType === MessageType.WARNING_MESSAGE_TYPE &&
            (
                <div className="alert alert-dark d-flex align-items-center" role="alert"
                     onLoad={toggleFadeAnimation(true)}>
                    <i className="bi bi-exclamation-circle-fill" role="img" aria-label="Warning:"/>
                    <div className="ms-2">
                        {message}
                    </div>
                </div>
            )
            }

            {display && messageType === MessageType.ERROR_MESSAGE_TYPE &&
            (
                <div className="alert alert-dark d-flex align-items-center" role="alert"
                     onLoad={toggleFadeAnimation(true)}>
                    <i className="bi bi-x-circle-fill" role="img" aria-label="Danger:"/>
                    <div className="ms-2">
                        {message}
                    </div>
                </div>
            )
            }
        </div>
    )
}