class Objects {

    static isNotNull(object) {
        return object && (typeof object !== 'undefined');
    }

    static isNull(object) {
        return !object || (typeof object === 'undefined');
    }
}

export default Objects;