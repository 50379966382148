import React from "react";
import ContactInformationPopulator from "../utils/ContactInformationPopulator";

const text = {
    workingHours: {
        weAreOpen: 'We are open ',
        from: ' from '
    }
}

export default function ContactInformation() {

    React.useEffect(() => {
        ContactInformationPopulator.populate()
    }, []);

    return (
        <div className="w-100 d-block">
            <table className="table table-borderless align-middle">
                <tbody>
                <tr>
                    <td className="p-2">
                        <span><i className="bi bi-geo-fill"/></span>
                    </td>
                    <td title="Pop by at our offices">
                        <small className="business-address-content"/>
                    </td>
                </tr>
                <tr>
                    <td className="p-2">
                        <span><i className="bi bi-envelope-fill"/></span>
                    </td>
                    <td title="Send us an email">
                        <small className="email-address-content"/>
                    </td>
                </tr>
                <tr>
                    <td className="p-2">
                        <span><i className="bi bi-phone-vibrate-fill"/></span>
                    </td>
                    <td title="Give us a call">
                        <small className="office-number-content"/>
                    </td>
                </tr>
                <tr>
                    <td className="p-2">
                        <span><i className="bi bi-clock-fill"/></span>
                    </td>
                    <td>
                        <small>
                            {text.workingHours.weAreOpen}
                            <strong>Mondays</strong> to <strong>Saturday</strong>
                            {text.workingHours.from}
                            <strong>8AM</strong> till <strong>5PM</strong>
                        </small>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}