import React from "react";
import QuotePageHeader from "../components/QuotePageHeader";
import {Link} from "react-router-dom";

const text = {
    affordableTuitionServices: {
        firstPart: 'We provides our professional tutoring services either ',
        secondPart: ' using video conferencing apps like Zoom & Skype, or ',
        thirdPart: ' at the comfort of your home and at the time that suits you & your child.'
    },

    covid19ProtocolAdherence: '* Our in-person tuition services adhere to the COVID-19 requirements, ' +
        'as prescribed by the governing laws of the Republic of South Africa.',

    inPersonTutoring: 'One-on-one or in-person tuition services with one of our tutors at the comfort of your home ' +
        'and at the time it is most suitable for you and your child.',

    onlineTutoring: 'Online tuition services with one of our tutors over the Internet using apps such as ' +
        'Zoom & Skype and at the time it is most suitable for you and your child.',

    groupClassesAndTuition: 'In-person or online tuition services with one of our tutors at the comfort of your ' +
        'home and at the time it is most suitable for you with two or more students at a time.'
}

const quote = {
    text: "The best way to find yourself is to lose yourself in the service of others.",
    source: "Mahatma Gandhi"
}

const renderFindTutorButton = function () {
    return (
        <Link to="/find-a-tutor" title="Find a tutor" className="btn btn-lg btn-outline-light"
              style={{marginTop: "1.25em"}}>
            <i className="bi bi-person-check-fill"/> Get started
        </Link>
    )
}

export default function Services() {
    return (
        <React.Fragment>
            <header>
                <QuotePageHeader headerTitle="Services"
                                 quote={quote}/>
            </header>
            <main>
                <div className="w-100 d-block no-padding">

                    <div className="container-fluid no-padding max-width-1920px">

                        <div className="empty-divider hide-on-max-width-575px"/>

                        <div className="row flex-grow-1 h-100 justify-content-center fade-in-animation">
                            <div className="col-lg-6 col-12 p-md-4 padding-one-eight">
                                <h3>Affordable quality tuition services</h3>
                                <p style={{fontSize: "115%"}}>
                                    {text.affordableTuitionServices.firstPart}
                                    <strong className="text-highlighted-red">online</strong>
                                    {text.affordableTuitionServices.secondPart}
                                    <strong className="text-highlighted-red">in-person *</strong>
                                    {text.affordableTuitionServices.thirdPart}
                                </p>
                                <br/>
                                <p>
                                    <small className="text-muted">
                                        {text.covid19ProtocolAdherence}
                                    </small>
                                </p>
                            </div>
                        </div>

                        <div className="empty-divider hide-on-max-width-575px"/>
                    </div>

                    <div className="container-fluid no-padding max-width-1920px">

                        <div className="row flex-grow-1 h-100 fade-in-animation">

                            <div className="col-lg-6 order-lg-1 order-1 no-padding text-center red-solid-background">
                                <div className="w-100 padding-one-eight">
                                    <h1><i className="bi bi-people-fill"/></h1>
                                    <h2>In-person tutoring</h2>
                                    <hr/>
                                    <p>
                                        {text.inPersonTutoring}
                                    </p>

                                    {renderFindTutorButton()}
                                </div>
                            </div>

                            <div
                                className="col-lg-6 order-lg-2 order-2 min-height-424px teacher-instructing-student-image-background"/>

                        </div>

                        <div className="row flex-grow-1 h-100 fade-in-animation">

                            <div className="col-lg-6 order-lg-2 order-1 no-padding text-center red-solid-background">
                                <div className="w-100 padding-one-eight">
                                    <h1><i className="bi bi-laptop"/></h1>
                                    <h2>Online tutoring</h2>
                                    <hr/>
                                    <p>
                                        {text.onlineTutoring}
                                    </p>

                                    {renderFindTutorButton()}
                                </div>
                            </div>

                            <div
                                className="col-lg-6 order-lg-1 order-2 min-height-424px girl-greeting-tutor-online-image-background"/>
                        </div>

                        <div className="row flex-grow-1 h-100 fade-in-animation">

                            <div className="col-lg-6 order-lg-1 order-1 no-padding text-center red-solid-background">
                                <div className="w-100 padding-one-eight">
                                    <h1><i className="bi bi-people-fill"/></h1>
                                    <h2>Group classes & tuition</h2>
                                    <hr/>
                                    <p>
                                        {text.groupClassesAndTuition}
                                    </p>

                                    {renderFindTutorButton()}
                                </div>
                            </div>

                            <div
                                className="col-lg-6 order-lg-2 order-2 min-height-424px students-studying-outside-image-background"/>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}