import React from "react";
import CallMeBackRequestForm from "../components/CallMeBackRequestForm";
import JigglySocialMediaIcons from "../components/JigglySocialMediaIcons";
import ContactInformation from "../components/ContactInformation";
import QuotePageHeader from "../components/QuotePageHeader";

const text = {
    letUsGetInTouch: 'If you\'d like us to find your child the perfect tutor or for any other inquiries you may have, ' +
        'please do not hesitate to contact us. We are always happy to assist you!'
}

const quote = {
    text: "What would life be if we had no courage to attempt anything?",
    source: "Vincent Van Gogh"
}

export default function Contact() {

    return (
        <React.Fragment>
            <header>
                <QuotePageHeader headerTitle="Contact us"
                                 quote={quote}/>
            </header>
            <main>
                <div className="w-100 d-block no-padding">

                    <div className="container-fluid no-padding max-width-1920px">

                        <div className="empty-divider hide-on-max-width-575px"/>

                        <div className="row flex-grow-1 h-100 justify-content-center fade-in-animation">
                            <div className="col-lg-6 col-12 p-md-4 padding-one-eight">
                                <h3>Let's get in touch!</h3>
                                <p style={{fontSize: '115%'}}>
                                    {text.letUsGetInTouch}
                                </p>
                            </div>
                        </div>

                        <div className="empty-divider hide-on-max-width-575px"/>
                    </div>

                    <div className="container-fluid no-padding max-width-1920px">

                        <div className="row flex-grow-1 h-100 min-height-424px fade-in-animation">

                            <div className="col-lg-6 order-lg-1 order-1 no-padding black-solid-background">
                                <div className="w-100 padding-one-eight">
                                    <p>Ready to find your perfect tutor?</p>
                                    <h3><i className="bi bi-chat-dots-fill"/> Let's keep in touch<span
                                        className="text-danger">.</span></h3>
                                    <hr/>

                                    <ContactInformation/>
                                    <br/>

                                    <p>Let's connect, follow us on our social platforms</p>
                                    <div className="w-75 text-align-left">
                                        <JigglySocialMediaIcons/>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col-lg-6 order-lg-1 order-1 no-padding white-textured-paper-image-background">
                                <div className="w-100 padding-one-eight">
                                    <p>Or, we could always call you back</p>
                                    <h3><i className="bi bi-telephone-inbound-fill"/> Request a call back</h3>
                                    <br/>
                                    <CallMeBackRequestForm/>
                                </div>
                            </div>

                        </div>

                        <div className="row flex-grow-1 h-100 fade-in-animation">
                            <div className="col-lg-12 no-padding min-height-424px">
                                <div id="google-maps-embed-block">
                                    <div className="map-outer">
                                        <div className="google-maps-canvas">
                                            <iframe width="600" height="600" className="google-maps-iframe" title="Business Address"
                                                    src="https://maps.google.com/maps?q=15%20Soetdoring%20Avenue,%20Weltevredenpark,%20Roodepoort,%201709&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                                    frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </main>
        </React.Fragment>
    )
}