class SessionStoreManager {

    static setAttribute(attributeName, attributeObject) {
        if (attributeName && typeof attributeName === 'string' && attributeObject) {
            sessionStorage.setItem(attributeName, JSON.stringify(attributeObject));
        }
    }

    static getAttribute(attributeName) {
        if (attributeName && typeof attributeName === 'string') {
            const item = sessionStorage.getItem(attributeName);
            if (item && typeof item === 'string') {
                return JSON.parse(item);
            }
        }
        return null;
    }

    static removeAttribute(attributeName) {
        if (attributeName && typeof attributeName === 'string') {
            sessionStorage.removeItem(attributeName);
        }
    }

}

export default SessionStoreManager;