import React from "react";
import { useLocation } from "react-router-dom";

const scrollToTop = function () {
    window.scrollTo(0, 0);
}

export default function ScrollToTop({children}) {
    const pathLocation = useLocation();

    React.useEffect(scrollToTop, [pathLocation])

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
}