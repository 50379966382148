import React from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import MessageAlert from "./MessageAlert";
import MessageType from "../utils/MessageType";
import SessionConfigOptions from "../utils/SessionConfigOptions";
import SessionStoreManager from "../utils/SessionStoreManager";
import Constants from "../utils/Constants";

const text = {
    consentDeclaration: {
        firstPart: 'By submitting your request, you agree to our ',
        secondPart: ' and '
    }
}

const emailJSConfigOptions = {
    serviceId : process.env.REACT_APP_EMAILJS_SERVICE_ID,
    templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    userId: process.env.REACT_APP_EMAILJS_USER_ID
}

export default function CallMeBackRequestForm() {

    let sessionConfigOptions = SessionStoreManager.getAttribute(SessionConfigOptions.ATTRIBUTE_NAME) || {};

    const [callBackRequest, setCallBackRequest] = React.useState({
        fullNames: '',
        contactNumber: '',
        emailAddress: ''
    });

    const [displayOptions, setDisplayOptions] = React.useState({
        disableSubmitButton: false,
        enableSpinner: false,
        disableInputFields: false
    });

    const [alertMessage, setAlertMessage] = React.useState({
        display: false,
        messageType: MessageType.NONE,
        message: ''
    });

    const onFullNamesChange = function (event) {
        const value = event.target.value;
        setCallBackRequest({
            ...callBackRequest,
            fullNames: (value) ? value.toUpperCase() : ''
        })
    }

    const onContactNumberChange = function (event) {
        const value = event.target.value;
        setCallBackRequest({
            ...callBackRequest,
            contactNumber: isNaN(value) ? '' : value
        })
    }

    const onEmailAddressChange = function (event) {
        const value = event.target.value;
        setCallBackRequest({
            ...callBackRequest,
            emailAddress: (value) ? value.toLowerCase() : ''
        })
    }

    const callBackRequestResponseHandler = function (response) {
        if (response.status === 200) {
            setAlertMessage({
                display: true,
                messageType: MessageType.SUCCESS_MESSAGE_TYPE,
                message: 'Your request for a call back from us has been received. ' +
                    'One of our staff will get back to you as soon as possible, thank you!'
            });

            sessionConfigOptions.sentCallBackRequest = true;
            SessionStoreManager.setAttribute(SessionConfigOptions.ATTRIBUTE_NAME, sessionConfigOptions);
        } else {
            setAlertMessage({
                display: true,
                messageType: MessageType.WARNING_MESSAGE_TYPE,
                message: 'Your call back request could not be sent due to a technical error. ' +
                    'If this is urgent, please consider options on how to contact us from the ' +
                    '\'Contact Us\' page.'
            });
        }

        setDisplayOptions({
            enableSpinner: false,
            disableSubmitButton: false,
            disableInputFields: false
        });
    }

    const callBackRequestErrorHandler = function (error) {
        setAlertMessage({
            display: true,
            messageType: MessageType.ERROR_MESSAGE_TYPE,
            message: error.message
        });

        setDisplayOptions({
            enableSpinner: false,
            disableSubmitButton: false,
            disableInputFields: false
        });
    }

    const onSubmitForm = function (event) {
        event.preventDefault();

        if (sessionConfigOptions && sessionConfigOptions.sentCallBackRequest) {
            setAlertMessage({
                display: true,
                messageType: MessageType.SUCCESS_MESSAGE_TYPE,
                message: 'You\'ve already sent your request for a call back. ' +
                    'One of our staff will be in contact with your shortly.'
            });

            return;
        }

        setDisplayOptions({
            enableSpinner: true,
            disableSubmitButton: true,
            disableInputFields: true
        });

        const data = {
            service_id: emailJSConfigOptions.serviceId,
            template_id: emailJSConfigOptions.templateId,
            user_id: emailJSConfigOptions.userId,
            template_params: {
                'full_names': callBackRequest.fullNames,
                'email_address': callBackRequest.emailAddress,
                'contact_number': callBackRequest.contactNumber
            }
        }

        axios.post(Constants.EMAILJS_SEND_URL, data)
            .then((response) => {
                callBackRequestResponseHandler(response);

                setDisplayOptions({
                    enableSpinner: false,
                    disableSubmitButton: false,
                    disableInputFields: false
                });
            })
            .catch((error) => {
                callBackRequestErrorHandler(error);

                setDisplayOptions({
                    enableSpinner: false,
                    disableSubmitButton: false,
                    disableInputFields: false
                });
            });
    }

    const init = function () {
        setDisplayOptions({
            enableSpinner: false,
            disableSubmitButton: false,
            disableInputFields: false
        });

        setAlertMessage({
            display: false,
            messageType: MessageType.NONE,
            message: ''
        });
    }

    React.useEffect(init, []);

    return (
        <React.Fragment>
            <MessageAlert display={alertMessage.display}
                          messageType={alertMessage.messageType}
                          message={alertMessage.message}/>

            <form onSubmit={onSubmitForm}>
                <div className="mb-3">
                    <label className="form-label" htmlFor="full-names-input">Full Names</label>
                    <input className="form-control no-border-radius" id="full-names-input"
                           placeholder="Enter your first and last name" type="text" required="required"
                           size="64" title="Full Names" value={callBackRequest.fullNames}
                           onChange={onFullNamesChange} disabled={displayOptions.disableInputFields}/>
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="contact-number-input">Contact Number</label>
                    <input className="form-control no-border-radius" id="contact-number-input"
                           placeholder="Enter your contact number" type="text" required="required" size="10"
                           title="Contact Number" value={callBackRequest.contactNumber}
                           onChange={onContactNumberChange} disabled={displayOptions.disableInputFields}/>
                </div>

                <div className="mb-3">
                    <label className="form-label" htmlFor="email-address-input">Email</label>
                    <input className="form-control no-border-radius" id="email-address-input"
                           placeholder="Enter your email address" type="email" required="required" size="128"
                           title="Email Address" value={callBackRequest.emailAddress}
                           onChange={onEmailAddressChange} disabled={displayOptions.disableInputFields}/>
                </div>

                <br/>

                <p className="text-muted">
                    <small>
                        {text.consentDeclaration.firstPart}
                        <Link to="/legal/terms-of-use" title="Terms of Use">
                            <strong>Terms of Use</strong>
                        </Link>
                        {text.consentDeclaration.secondPart}
                        <Link to="/legal/privacy-policy" title="Privacy Policy">
                            <strong>Privacy Policy</strong>
                        </Link>.
                    </small>
                </p>

                <br/>

                <button type="submit" className="btn btn-danger" disabled={displayOptions.disableSubmitButton}>
                    <i className="bi bi-telephone-inbound-fill"/> Call me back
                </button>

                {displayOptions.enableSpinner &&
                (
                    <div className="d-block text-align-left p-2">
                        <div className="spinner-grow spinner-grow-sm text-danger" style={{display: "inline-block"}}
                             role="status"/>
                        <div className="spinner-grow spinner-grow-sm text-danger" style={{display: "inline-block"}}
                             role="status"/>
                        <div className="spinner-grow spinner-grow-sm text-danger" style={{display: "inline-block"}}
                             role="status"/>

                        <small className="text-muted"> Please wait... </small>
                    </div>
                )}
            </form>
        </React.Fragment>
    )
}