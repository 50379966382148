import $ from "jquery";

const TELEPHONE_NUMBER = '078 433 7742';
const EMAIL_ADDRESS = 'info@xplain2me.co.za';
const BUSINESS_ADDRESS = '15 Soetdoring Avenue, Weltevredenpark, Roodepoort, 1709';

class ContactInformationPopulator {

    static populate() {
        $('.business-address-content')
            .html(BUSINESS_ADDRESS);

        $('.email-address-content')
            .html(EMAIL_ADDRESS)
            .on('click', function () {
                window.open('mailto:' + EMAIL_ADDRESS, '_blank');
            });

        $('.office-number-content')
            .html(TELEPHONE_NUMBER)
            .on('click', function () {
                window.open('tel:' + TELEPHONE_NUMBER, '_blank');
            });
    }
}

export default ContactInformationPopulator;