import React from "react";
import {Link} from "react-router-dom";
import $ from "jquery";
import RequestTutorPopupButton from "./RequestTutorPopupButton";

const collapseNavbarAfterNavItemClick = function () {
    const navbarTogglerButton = $('#navbarTogglerButton');
    const navbarSupportedContent = $('#navbarSupportedContent');

    $(navbarSupportedContent).removeClass('show');
    $(navbarTogglerButton).addClass('collapsed');
}

export default function TopFixedNavigationBar() {

    const initEffect = function () {
        $('.nav-item').on('click', function () {
            collapseNavbarAfterNavItemClick()
        })
    }

    React.useEffect(initEffect, []);

    return (
        <div className="navbar-container fade-in-animation">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">

                    <a className="navbar-brand" href="/" title="Xplain2Me Tutoring">
                        <img src="https://assets.xplain2me.co.za/images/XPLAIN2ME_TUTORING_LOGO.png" alt="Xplain2Me Tutoring"/>
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation" id="navbarTogglerButton">
                        <span className="navbar-toggler-icon"/>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item active" >
                                <a className="nav-link" title="Home" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className="nav-link" title="About">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/services" className="nav-link" title="Services">Services</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link" title="Contact us">Contact us</Link>
                            </li>
                        </ul>

                        <div className="d-flex navbar-search-form">
                            <RequestTutorPopupButton buttonText="Find a tutor"
                                                     darkTheme={true} largeButton={false} />

                            {/*<button type="button"*/}
                            {/*        className="btn btn-outline-light btn-icon-no-fill-no-border search-all-button">*/}
                            {/*    <i className="bi bi-search"/>*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}