import React from "react";
import QuotePageHeader from "../../components/QuotePageHeader";

const quote = {
    text: "Education is not the learning of facts, but the training of the mind.",
    source: "Albert Einstein"
}

export default function ResourceNotFound() {
    return (
        <React.Fragment>
            <header>
                <QuotePageHeader headerTitle="Page Not Found"
                                 quote={quote}/>
            </header>
            <main>
                <div className="w-100 d-block no-padding">

                    <div className="container-fluid no-padding max-width-1920px">

                        <div className="empty-divider hide-on-max-width-575px"/>

                        <div className="row flex-grow-1 h-100 justify-content-center fade-in-animation">
                            <div className="col-lg-6 col-12 p-md-4 padding-one-eight">
                                <h3>
                                    <i className="bi bi-exclamation-circle-fill"/> Oops, something went wrong
                                </h3>
                                <p style={{fontSize: "115%"}}>
                                    We're sorry but looks like something went wrong. Please check that your request for
                                    the resource is correct.
                                </p>
                                <p>
                                    Otherwise, it might be an unexpected error from our side. If this persists, please
                                    contact us for more assistance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}