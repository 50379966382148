import React from "react";
import Objects from "../utils/Objects";

export default function QuotePageHeader({headerTitle, quote}) {

    const quotePresent = Objects.isNotNull(quote);

    return (
        <div className="default-header einstein-graffiti-on-wall-image-background fixed-or-scroll-image-background">
            <div className="empty-divider"/>
            <div className="d-flex justify-content-evenly text-center p-4 fade-in-animation">
                <div className="p-4">
                    <h1>{headerTitle}</h1>
                    <div className="empty-divider"/>
                    {quotePresent &&
                    (
                        <p>
                            <span>"{quote.text}"</span>
                            <br/>
                            <small>{quote.source}</small>
                        </p>
                    )}
                </div>
            </div>

            <div className="empty-divider"/>
        </div>
    )
}