import React from "react";
import QuotePageHeader from "../components/QuotePageHeader";

const text = {
    learnMoreAboutUs: 'is a registered private company and was founded in 2015 by a group of university students who ' +
        'are passionate about education and have the urge and determination to assist others with their academics and ' +
        'to pass down significant and insightful knowledge to those who need it the most.',

    howOurCompanyWasFounded: 'The founders of the company are university graduates, with some graduated from the ' +
        'University of the Witwatersrand and the University of Johannesburg and have worked in the professional ' +
        'industry as Chartered Accountants, Computer Scientists and Auditors.',

    teamBehindTheCompany: {
        firstPart: 'The team behind ',
        secondPart: ' has more than 8 years of experience in private tutoring and has grown to understand the need and ' +
            'the importance of preparing and equipping students with the knowledge they need for their success and ' +
            'for their future going forward.'
    }
}

const quote = {
    text: "Every new beginning comes from some other beginning's end.",
    source: "Seneca"
}

export default function About() {
    return (
        <React.Fragment>
            <header>
                <QuotePageHeader headerTitle="About us"
                                 quote={quote}/>
            </header>
            <main>
                <div className="w-100 d-block no-padding">

                    <div className="container-fluid no-padding max-width-1920px">
                        <div className="empty-divider hide-on-max-width-575px"/>

                        <div className="row flex-grow-1 h-100 justify-content-center fade-in-animation">
                            <div className="col-lg-6 col-12 p-md-4 padding-one-eight">
                                <h3>Learn more about us</h3>
                                <p style={{fontSize: '115%'}}>
                                    <strong className="text-highlighted-red">Xplain2Me
                                        Tutoring</strong> {text.learnMoreAboutUs}
                                </p>
                            </div>
                        </div>

                        <div className="empty-divider hide-on-max-width-575px"/>
                    </div>

                    <div
                        className="background-only-wide-block kids-pulling-rope-image-background fixed-or-scroll-image-background fade-in-animation"/>

                    <div className="container-fluid no-padding max-width-1920px">
                        <div className="row flex-grow-1 h-100 min-height-424px fade-in-animation">
                            <div
                                className="col-lg-6 order-lg-1 order-1 no-padding text-center red-solid-background arrow-popup-wrapper">
                                <div className="arrow-right-popout-block red-solid-background"/>
                                <div className="w-100 padding-one-eight jiggle-on-hover">
                                    <h3>All great things have humble beginnings...</h3>
                                    <hr/>
                                    <p>How our company was founded</p>
                                </div>
                            </div>

                            <div
                                className="col-lg-6 order-lg-2 order-2 no-padding text-center white-textured-paper-image-background">
                                <div className="w-100 padding-one-eight">
                                    <p>
                                        {text.howOurCompanyWasFounded}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="row flex-grow-1 h-100 min-height-424px fade-in-animation">

                            <div
                                className="col-lg-6 order-lg-2 order-1 no-padding text-center black-solid-background arrow-popup-wrapper">
                                <div className="arrow-left-popout-block black-solid-background"/>
                                <div className="w-100 padding-one-eight jiggle-on-hover">
                                    <h3>Backed by a team of qualified graduates</h3>
                                    <hr/>
                                    <p>The team is a group of passionate graduates with professional experience.</p>
                                </div>
                            </div>

                            <div
                                className="col-lg-6 order-lg-1 order-2 no-padding text-center white-textured-paper-image-background">
                                <div className="w-100 padding-one-eight">
                                    <p>
                                        {text.teamBehindTheCompany.firstPart}
                                        <strong className="text-highlighted-red">Xplain2Me Tutoring</strong>
                                        {text.teamBehindTheCompany.secondPart}
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="row flex-grow-1 h-100 min-height-424px fade-in-animation">

                            <div
                                className="col-lg-6 order-lg-1 order-1 no-padding text-center red-solid-background arrow-popup-wrapper">
                                <div className="arrow-right-popout-block red-solid-background"/>
                                <div className="w-100 padding-one-eight jiggle-on-hover">
                                    <h3>Our drive, our passion...</h3>
                                    <hr/>
                                    <p>What truly drives our team?</p>
                                </div>
                            </div>

                            <div
                                className="col-lg-6 order-lg-2 order-2 no-padding white-textured-paper-image-background">
                                <div className="w-100 padding-one-eight text-align-left">
                                    <ul>
                                        <li>The pride and delight of a parent whose child has excelled in their
                                            academics.
                                        </li>
                                        <li>The joy of a student who has passed his/her exams.</li>
                                        <li>The smile of a student whose confidence is back after improving in their
                                            academics.
                                        </li>
                                        <li>The radiance of a student who becomes a graduate.</li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}