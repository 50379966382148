import React from "react";
import $ from "jquery";

export default function JigglySocialMediaIcons() {

    React.useEffect(() => {
        $('.facebook-icon').on('click', function () {
            window.open('https://www.facebook.com/Xplain2meTutoring', '_blank');
        });

        $('.instagram-icon').on('click', function () {
            window.open('https://www.instagram.com', '_blank');
        });

        $('.twitter-icon').on('click', function () {
            window.open('https://www.twitter.com', '_blank');
        });

        $('.youtube-icon').on('click', function () {
            window.open('https://www.youtube.com', '_blank');
        });
    }, []);

    return (
        <div className="d-flex justify-content-evenly text-center p-4">
            <h2 className="social-media-icon-wrapper facebook-icon jiggle-animation" title="Follow us on Facebook">
                <i className="bi bi-facebook" />
            </h2>
            <h2 className="social-media-icon-wrapper instagram-icon jiggle-animation" title="Follow us on Instagram">
                <i className="bi bi-instagram" />
            </h2>
            <h2 className="social-media-icon-wrapper twitter-icon jiggle-animation" title="Follow us on Twitter">
                <i className="bi bi-twitter" />
            </h2>
            <h2 className="social-media-icon-wrapper youtube-icon jiggle-animation" title="Subscribe on YouTube">
                <i className="bi bi-youtube" />
            </h2>
        </div>
    )
}