import React from "react";
import ContactInformation from "../components/ContactInformation";
import CallMeBackRequestForm from "../components/CallMeBackRequestForm";
import HomeGlideSliderContainer from "../components/HomeGlideSliderContainer";
import RequestTutorPopupButton from "../components/RequestTutorPopupButton";

const text = {
    brighterFutureAwaitsYou: {
        firstPart: 'A brighter future towards your ',
        secondPart: ' is waiting for you'
    },
    connectYourChildWithPerfectTutor: {
        firstPart: 'We can connect your child with the ',
        secondPart: 'We match the perfect tutor to your child\'s learning needs for '
    },
    weCanHelpWithMostSubjects: {
        firstPart: 'We can help with ',
        secondPart: ' from ',
        thirdPart: 'No worries. No matter the subject, grade or level, we got you!'
    },
    weOfferOurServicesInRegions: {
        firstPart: 'We offer our services around the ',
        secondPart: ' regions, with a wide subject and grade coverage from primary to ' +
            'secondary school and some university subjects.'
    },
    affordableRates: {
        firstPart: 'Our rates are ',
        secondPart: ' and yet we offer the best service with utilization  of qualified ' +
            'and trained tutors. We ensure our clients get ',
        thirdPart: ' by following up regularly on student performance and ensuring progress ' +
            'is being made.'
    }
}

export default function Home() {
    return (
        <main>
            <div className="container-fluid">

                <div className="row flex-grow-1 h-100">
                    <div
                        className="col-lg-6 tutor-helping-others-image-background no-padding glide-background-ltr-animation"
                        style={{minHeight: '70vh', zIndex: '2'}}>
                        <div className="large-square-block fade-in-animation">
                            <div className="bottom-aligned-display-legend">
                                <h1 className="heading-text">
                                    {text.brighterFutureAwaitsYou.firstPart}
                                    <span className="emphasised-heading-text">academic success</span>
                                    {text.brighterFutureAwaitsYou.secondPart}
                                </h1>
                                <br/>
                                <div className="d-flex">
                                    <RequestTutorPopupButton darkTheme={false} largeButton={true}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 no-padding" style={{zIndex: "1"}}>
                        <div className="row flex-grow-1">
                            <div className="col-lg-12 red-solid-background no-padding">
                                <HomeGlideSliderContainer/>
                            </div>
                        </div>

                        <div className="w-100 d-block"/>

                        <div className="row flex-grow-1">
                            <div className="col-sm-6 white-textured-paper-image-background no-padding">
                                <div className="w-100 h-100 p-4 fade-in-animation">
                                    <div className="w-100 slightly-smaller-font-size text-align-right">
                                        <h4><i className="bi bi-person-check-fill"/></h4>
                                        <h4>
                                            {text.connectYourChildWithPerfectTutor.firstPart}
                                            <span className="text-danger">perfect tutor</span>.
                                        </h4>
                                        <hr/>
                                        <p>
                                            {text.connectYourChildWithPerfectTutor.secondPart}
                                            <strong>perfect results</strong>.
                                        </p>
                                        <br/>
                                        <RequestTutorPopupButton darkTheme={true} largeButton={false}
                                                                 buttonText="Connect me now"/>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col-sm-6 boy-with-books-on-his-lap-image-background background-image-zoom-in-effect no-padding">
                                <div className="w-100 h-100 min-height-292px"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-100"/>

                <div className="row flex-grow-1">

                    <div className="col-lg-3 col-sm-6 white-textured-paper-image-background no-padding">
                        <div
                            className="w-100 h-100 p-4 text-center slightly-smaller-font-size fade-in-animation">
                            <h4><i className="bi bi-easel2-fill"/></h4>
                            <h4>
                                {text.weCanHelpWithMostSubjects.firstPart}
                                <span className="text-danger">most subjects</span>
                                {text.weCanHelpWithMostSubjects.secondPart}
                                <span className="text-danger">Grade 1 to tertiary</span>!
                            </h4>
                            <hr/>
                            <p>
                                {text.weCanHelpWithMostSubjects.thirdPart}
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 no-padding girl-with-books-on-lap-image-background">
                        <div className="w-100 h-100 min-height-292px"/>
                    </div>

                    <div className="col-lg-6 col-sm-12 red-solid-background no-padding">
                        <div
                            className="w-100 h-100 p-4 text-center slightly-smaller-font-size fade-in-animation">
                            <h2><i className="bi bi-geo-alt-fill"/></h2>
                            <h4>We're available in...</h4>
                            <br/>
                            <h2>Jo'burg, East Rand & Pretoria</h2>
                            <hr/>
                            <p>
                                {text.weOfferOurServicesInRegions.firstPart}
                                <strong>Johannesburg</strong> and <strong>Pretoria</strong>
                                {text.weOfferOurServicesInRegions.secondPart}
                            </p>
                            <br/>
                            <RequestTutorPopupButton largeButton={true} darkTheme={false}
                                                     buttonText="Find me tutors near me"/>
                        </div>
                    </div>

                </div>

                <div className="row flex-grow-1">

                    <div className="col-lg-6 col-sm-12 lady-happy-on-video-call-image-background no-padding">
                        <div className="w-100 h-100 p-4 text-center fade-in-animation" style={{color: '#fff'}}>
                            <h2><i className="bi bi-piggy-bank-fill"/></h2>
                            <h3>Affordable rates with great quality!</h3>
                            <hr/>
                            <p>
                                {text.affordableRates.firstPart}
                                <strong>affordable</strong>
                                {text.affordableRates.secondPart}
                                <strong>value for their money</strong>
                                {text.affordableRates.thirdPart}
                            </p>
                            <br/>
                            <RequestTutorPopupButton />
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 black-solid-background no-padding">
                        <div className="w-100 h-100 p-4 fade-in-animation">
                            <p>Ready to find your tutor?</p>
                            <h3><i className="bi bi-chat-dots-fill"/> Contact us<span className="text-danger">.</span>
                            </h3>
                            <hr/>
                            <ContactInformation/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 white-textured-paper-image-background no-padding">
                        <div className="w-100 h-100 p-4 fade-in-animation">
                            <p>Or, we could always call you back</p>
                            <h3><i className="bi bi-telephone-inbound-fill"/> Call me back</h3>
                            <hr/>

                            <div className="slightly-smaller-font-size">
                                <CallMeBackRequestForm/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    )
}