import React from "react";

const text = {
    onlineAndFaceToFaceTutoring: 'We offer private one-on-one tuition on various subjects to your child either ' +
        'online or at the comfort of your home from anywhere in the country.',

    wePickOnlyTheBestTutors: 'We hire only the best tutors in the market to give your child the confidence and ' +
        'success they require.',

    attentionToYourChildNeeds: 'We analyse and formulate lesson plans and learning methods best suited their ' +
        'academic needs, continually monitoring progress and giving you feedback.'
}

export default function HomeGlideSliderContainer() {

    return (
        <div className="glide glide-slider-container h-100 fade-in-animation">
            <div className="glide__track" data-glide-el="track">

                <div className="glide__slides h-100">

                    <div className="glide__slide glide-slider-slide">
                        <div className="row flex-grow-1 h-100">
                            <div
                                className="col-sm-6 col-xs-4 boy-learning-online-image-background only-when-min-width-575 no-padding">
                                <div className="w-100"/>
                            </div>
                            <div className="col-sm-6 col-xs-8 text-center no-padding">
                                <div className="w-90 p-4 adjust-slide-content">
                                    <h4>
                                        <i className="bi bi-laptop"/>&nbsp;<i className="bi bi-people-fill"/>
                                    </h4>
                                    <h4><span>Online & face-to-face tutoring</span></h4>
                                    <hr/>
                                    <p className="slightly-smaller-font-size">
                                        {text.onlineAndFaceToFaceTutoring}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="glide__slide glide-slider-slide">
                        <div className="row flex-grow-1 h-100">
                            <div
                                className="col-sm-6 col-xs-4 students-at-graduation-image-background only-when-min-width-575 no-padding">
                                <div className="w-100"/>
                            </div>
                            <div className="col-sm-6 col-xs-8 text-center no-padding">
                                <div className="w-90 p-4 adjust-slide-content">
                                    <h4><i className="bi bi-trophy-fill"/></h4>
                                    <h4>We pick only the best tutors</h4>
                                    <hr/>
                                    <p className="slightly-smaller-font-size">
                                        {text.wePickOnlyTheBestTutors}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="glide__slide glide-slider-slide">
                        <div className="row flex-grow-1 h-100">
                            <div
                                className="col-sm-6 col-xs-4 brainstorming-people-image-background only-when-min-width-575 no-padding">
                                <div className="w-100"/>
                            </div>
                            <div className="col-sm-6 col-xs-8 text-center no-padding">
                                <div className="w-90 p-4 adjust-slide-content">
                                    <h4><i className="bi bi-clipboard-data-fill"/></h4>
                                    <h4>Attention to your child's needs</h4>
                                    <hr/>
                                    <p className="slightly-smaller-font-size">
                                        {text.attentionToYourChildNeeds}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="glide__arrows" data-glide-el="controls">
                    <button className="glide__arrow glide__arrow--left" data-glide-dir="&lt;">
                        <i className="bi bi-chevron-left"/>
                    </button>
                    <button className="glide__arrow glide__arrow--right" data-glide-dir="&gt;">
                        <i className="bi bi-chevron-right"/>
                    </button>
                </div>

            </div>
        </div>
    )
}